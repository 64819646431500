.score_main{
    padding: 3% 10% 5%;
    background-color: #1D2029;
    position:absolute;
    bottom: 0;
    left:0;
    width:100%;
    transition: ease-out 0.3s;
    z-index: 7;
    border-radius: 5% 5% 0 0 ;
}
.score_main_close{
    padding: 3% 10%;
    position:absolute;
    bottom: -100%;
    left:0;
    width:100%;
    transition: ease-out 0.3s;
    z-index: 7;
    border-radius: 5% 5% 0 0 ;
    background-color: #1D2029;
}
.score_legend{
    display: block;
    width: auto;
    padding: 0 0.5rem;
    height: 21px;
    font-size: 0.75em;
    max-width: 100%;
    text-align: left;
    color:#E8A736;
    margin:0;
    margin-left:1rem;
    float:none;
    
}
.score_button_extract{
    border: 1px solid #E8A736;
    width: 100%;
    color:#E8A736;
    background-color: #2D3039;
    border-radius: 10px;
    height:2.5rem;
    font-size: 14px;
    margin:1.5rem 0 0 ;
}
.score_save_button{
    width: auto;
    height: 2.5rem;
    color: #2D3039;
    background-color: #E8A736;
    font-weight: 700;
    border-radius: 10px;
    border: none;
    width: 100%;
    margin-top: 1rem;
}
.score_form{
    background-color:#00000000;
    position: absolute;
    width: 100%;
    top:1rem;
    left:0;
    border: none;
    border-radius:10px;
    color:#E8A736;
    text-align: center;
    font-size: 18px;
}
.score_form:focus-visible{
    border:none;
    outline: none;
}


/* # для партнера */
div[new-value] {
    position: relative;
  }
div[new-value]:after {
    color:#E8A736;
    content: "#" attr(new-value);
    position: absolute;
    font-size: 18px;
    text-align: center;
    margin:0 auto;
    left: 0;
    right: 0;
    top: -0.25rem; 
}
div[new-value] input {
    color: #2D3039; 
    z-index: 0;
  }