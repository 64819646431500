.users_main{
    background-color: #1D2029;
    height: 100%;
    padding: 0 5%;
    width: 100vw;
    display:flex;
    flex-direction: column;
    box-sizing: border-box;
    /* flex:1; */
    /* position: absolute; */
    transition: ease-in 0.2s;
    overflow: hidden;
}

.users_title{
    color: #E8A736;
    text-align: left;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    width: 100%;

}
.users_icon_pos{
    position: absolute;
    top: 0.5rem;
    left:1rem;
    color: #585b667e;
    font-weight: 100;
}
.users_form{
    background-color: #4e566e50;
    margin: 0;
    border-radius: 10px;
    border: 1px solid #e8a73600;
    padding: 0.3rem  2.5rem ;
    width: 100%;
    font-size:14px;
    color:#ffffff8c;
}
.users_form:focus-visible{
    outline:1px solid #ffffff8c;
}
.users_form::placeholder{
    color:#585B66;
}
.users_fill{
    position:absolute;
    background-color: #1D2029;
    width: 100%;
    height:6px;
    bottom: -18px;
    z-index: 2;

}

.user_users_status_select {
    background: none;
    border: none;
    color: #C9C9C9;
    background-color: #2D3039;
    border-radius: 10px !important;
    font-size: 14px;
    /* width: 40%; */
    margin: 3% 0% 0% 0%;
    text-align-last: center;
    
}

:active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
}