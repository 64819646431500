.App {
  text-align: center;
  background-color: #1D2029;
  width: 100vw;
  height:100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}
