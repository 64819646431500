.user_info_main{
    position: absolute;
    bottom:0;
    left:0;
    padding: 3% 10% 3%;
    background-color: #1D2029;
    width: 100%;
    z-index: 4;
    transition: ease-in-out 0.3s;
    border-radius: 5% 5% 0 0 ;
}
.user_info_main_close{
    position: absolute;
    bottom:-150%;
    left:0;
    padding: 3% 10% 15%;
    background-color: #1D2029;
    width: 100%;
    z-index: 4;
    transition: ease-in-out 0.3s;
    border-radius: 5% 5% 0 0 ;
}
.user_info_button{
    width: auto;
    height: 3rem;
    color: #2D3039;
    background-color: #E8A736;
    font-weight: 700;
    border-radius: 10px;
    margin:0.5rem 0;
    border:0px;
}
.user_info_form{
    width: 100%;
    color: #e8a736;
    background-color: #2D3039;
    font-size: 14px;
    border-radius: 10px;
    padding: 0.5rem 0;    
    border:none;
}
.user_info_form::placeholder{
    color: #e8a73644;
}
.user_info_form:focus{
    outline: none;
    box-shadow: none;
    color: #e8a736;
    background-color: #2D3039;
    border:none;
}

.user_info_group{
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    background-color: #2D3039;
    border-radius: 10px;
    color:#E8A73644;
    font-size:14px;
    border:0px;
}

.user_dowl{
    margin: 0.5rem 0rem 0.5rem 0.5rem;
    /* padding: 0.5rem 0.5rem 0.5rem 0.8rem; */
    background-color: #2D3039;
    border-radius: 10px;
    color:#E8A73644;
    font-size:14px;
    border:0px;
    width: 15%;
}
.user_dowl_button{
    background-color: #2D3039;
    border-radius: 10px !important;
    color:#E8A73644;
    width: 12%;
    margin-left: auto!important;
    margin-right: 0!important;
}

.user_treaty_status_select{
    background: none;
    border: none;
    color: #e8a736;
    background-color: #2D3039;
    border-radius: 0 10px 10px 0 !important;
    font-size: 14px;
    width: 39%;
    white-space: nowrap; /* Текст не переносится */
    overflow: hidden; /* Обрезаем всё за пределами блока */
    text-overflow: ellipsis; /* Добавляем многоточие */
}
@media (max-width: 290px){
    .user_treaty_status_select{

        width: 20% !important;

    }
}
@media (max-width: 338px){
    .user_treaty_status_select{
   
        width: 30%;
        
    }
}
@media (min-width: 380px){
    .user_treaty_status_select{
   
        width: 42%;
        
    }
}

.MuiDialog-paper{
    background-color: #2D3039!important;
    color:#E8A736!important;
}
/*css-bkrceb-MuiButtonBase-root-MuiPickersDay-root*/
.MuiPickersDay-root{
    background-color: #2D3039!important;
    color:#E8A73644!important;
}
.MuiPickersDay-root:focus{
    color:#E8A736!important;
}
.MuiPickersDay-root.Mui-selected{
    color:#E8A736!important;
}
/*.css-nfrs8p-MuiButtonBase-root-MuiIconButton-root*/
.MuiIconButton-root{
    color:#E8A736!important;
}

/*css-1hbyad5-MuiTypography-root*/
.MuiTypography-root{
    color:#E8A736!important;
}
.MuiTypography-root.Mui-selected{
    color:#E8A736!important;
}


.MuiDateRangePickerDay-day.Mui-selected{
    background-color: #E8A73644!important;
}
.MuiDateRangePickerDay-day:not(.Mui-selected){
    border: 0px;
    color:#e8a736af!important;
   
}
.MuiDateRangePickerDay-day.Mui-selected:hover{
    background-color: #E8A73644;
}

.MuiDateRangePickerDay-rangeIntervalDayHighlight{
    background-color: #E8A73622!important;
    color:#E8A736!important;
}
.MuiPickersDay-today{
    color:#E8A736!important;
    border:1px solid #E8A736!important
} 
.MuiPickersDay-root.Mui-disabled{
    color:#E8A73611!important;
}
.MuiPickersDay-root{
    color:#e8a73646!important;
}
.MuiDateRangePickerDay-dayInsideRangeInterval{
    color:#E8A736!important;
    background-color: #E8A73611!important;
}

.MuiButton-root{
    color:#E8A736!important;
}





/**/
.PrivateDatePickerToolbar-penIcon{
    pointer-events: none;
    color:#E8A736!important;
}

/**/
.PrivateDateRangePickerToolbar-penIcon{
    pointer-events: none;
    color:#E8A736!important;

}




/* .user_info_form::after{
    content:"%";
    position: fixed;
    z-index: 99;
    top:10%;
    left:10%;
} */
div[data-value] {
    position: relative;
  }
div[data-value]:after {
    color:#E8A736;
    content: attr(data-value) "%";
    position: absolute;
    font-size: 14px;
    top: 0.5rem; 
    right:1rem;
    z-index: 3;
}

div[data-value]>input {
    color: #E8A736; /* Optional bug avoid visual bugs */    
  }
  .caretBlank{
    caret-color: #2D3039;
  }

  .truncate {
    /* background: #f4eddf; Цвет фона */
    /* padding: 10px; Поля вокруг текста */
    white-space: nowrap; /* Текст не переносится */
    overflow: hidden; /* Обрезаем всё за пределами блока */
    text-overflow: ellipsis; /* Добавляем многоточие */
   }