
.modal_blur{
    backdrop-filter:blur(10px)  brightness(80%);
    height:100%;
    width: 100%;
    z-index: 4;

    position: fixed;
    top:0;
    left:0;
    opacity: 1;
}  




.modal_main{
    background-color: #1D2029;
    color: #fff;
    padding: 5% 0;
    position:absolute;
    bottom:0;
    width:100%;
    z-index:5;
    bottom:0;
    left:0;
    transition: ease-out 0.3s;
    border-radius: 5% 5% 0 0 ;
    
}
.modal_main_close{
    background-color: #1D2029;
    color: #fff;
    padding: 5% 0;
    position:absolute;
    bottom:0;
    width:100%;
    z-index:5;
    bottom:-100%;
    left:0;
    
    transition: ease-out 0.3s;
}

.modal_button{
    background-color: #1D2029;
    border: 1px solid #B20000;
    color: #B20000;
    font-weight: 500;
    border-radius: 10px;
    padding: 0.5rem 2rem;
    flex:1;
    margin:3% 1%;
}
.modal_button_second{
    background-color: #1D2029;
    border: 1px solid #E8A736;
    color: #E8A736;
    font-weight: 500;
    border-radius: 10px;
    padding: 0.5rem 2rem;
    flex:1;
    margin:3% 1%;

}