
.img_tech {
    position: absolute;
    top:1%;
    left:0;
    height: 20%;
}
.tech_div{
    width: 35em;
    margin: 0 auto;
    font-family: Tahoma, Verdana, Arial, sans-serif;
    background-color: #1D2029;
    color:#E8A736;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}