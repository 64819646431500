.lion_site_main {
    background-color: #1D2029;
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: left;
}

.lion_site_content {
    margin: 7%;
    flex-direction: column;
    display: flex;
    flex: 1;
    z-index: 2;
}

.lion_site_textBlock {
    margin: 3%;
    max-width: 455px;
    width: 100%;
    flex-direction: column;
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.lion_site_highText {
    color: white;
    font-size: 48px;
    font-weight: 700;

    line-height: 3rem;

}

.lion_site_lion {
    position: absolute;
    right: 0;
    height: 100%;
    z-index: 1;
}

.lion_site_phones {
    position: absolute;
    left: -35%;
    top: 25%;
    height: 60%;
    z-index: 1;
}

.lion_site_logo {
    height: auto;
    max-width: 650px;
    width: 100%;

}

.lion_site_p {
    color: #555E78;
    margin-bottom: 3rem;
}

.lion_site_p>p {
    margin: 0;
    font-size: 20px;
}

.lion_site_p>p::before {
    content: "- ";
    color: #E8A736;
}

.lion_site_buttons {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.lion_site_buttons>img {
    height: auto;
}

@media screen and (max-width: 1000px) {
    .lion_site_phones {
        visibility: hidden;
    }

    .lion_site_lion {
        opacity: 0.5;
    }

    .lion_site_buttons>img {
        height: 45px;
    }
}