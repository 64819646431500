.auth_button{
    color:black;
    background-color: #E8A736;
    border: none;
    font-weight: 700;
    padding: 5px 40px;
    border-radius: 10px;
    margin:10px;
    transition: ease-in-out 0.3s;
}
.auth_main{
    background-color: #1D2029;
    padding: 40% 10%;
    height: 100vh;
}
.auth_icon_inside{
    position: absolute;
    top: 11px;
    left:15px;
    color: #e8a73696;

}
.auth_form{
    background-color: #2D3039;
    margin: 10px 0;
    border-radius: 10px;
    border: 1px solid #e8a73600;
    padding: 0.5rem  2.5rem ;
    color: #E8A736 !important;;
}
.auth_form::placeholder{
    color:#e8a73696;
}
.auth_form:focus{
    background-color: #2D3039;
    border: 1px solid #E8A736;
}
.auth_title{
    color: #E8A736;
    font-weight:500;
    font-size: 22px;
}
.auth_platform{
    margin:10px;
    height: 100%;
}
.auth_platform_border{
    margin:10px;
    height: 100%;
    border: 1px solid #E8A736;
    border-radius: 10px;
}
.auth_platform_blur{
    margin:10px;
    height: 100%;
    filter: blur(1px) brightness(75%);
    transition: ease-in-out 0.2s;
    
}