.footer_main{
    display: flex;
    justify-content: space-between;
    padding: 5% 10% 1%;
    background-color: #2D3039;
    border-radius: 10px 10px 0 0;
    position: relative;
    width: 100%;
    /* bottom: 0; */
    height:10%;
    box-sizing: border-box;
    box-shadow:0px -3px 8px 2px #1c1e25;
    z-index: 3;
}
.footer_img{
    height: 28px;
    width: auto;
    justify-content: center;
}
.footer_redEllipse_div{
    position: absolute;
    top: -10px;
    right: 5px;
    height: 17px;
    width: 17px;
}
.footer_img_ellipseRed{
    justify-content: left!important;
    height: 17px;
    width: auto;
    position: absolute;
    top:0;
    left:0;
    /* margin-left: 10px;
    margin-top: -10px; */
}
.footer_img_ellipseRed_textSumm{
    color: white!important;
    position: absolute;
    left:0;
    right: 0;
    top:10%;
    /* margin-left: 20px;
    margin-top: -8px; */
    font-size: 7pt!important;
    /* text-align: center!important; */
    z-index: 2;
}
.footer_dot{
    background-color: white;
    border: 5px solid #1D2029;
    height:16px;
    width: 16px;
    border-radius: 10px;
    margin:auto;
    z-index: 2;
    transition:ease-in-out 0.2s;
}
.footer_icon{
    position:relative;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.footer_icon>span{
    font-size: 10px;

}
.footer_block_dot{
    top:-5px;
    width:64px;
    position:absolute;
    /* transition: ease-in-out 0.3s; */
}