.hed_user_icon{
    position: relative;
    background-color: #272A32;
    border-radius: 10%;
    padding: 5px 5px 0 5px;
    height: 25px;
    width: 25px;
}
.hed_main{
    background-color: #1D2029;
    /* position: fixed; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5% 5% 3%;
    width: 100vw;
    height:auto;
    box-sizing: border-box;
    z-index: 2;
}
.hed_user_name{
    font-size: 12px;
    color: #C0C0C0;
    margin: 3px 5px;
    background-color:#1D2029 ;
}
.hed_user_arrow{
    font-size: 12px;
    color: #C0C0C0;
    margin:5px;
}
.hed_user_open{
    background-color: red;
    border-radius: 0 0 5px 5px;
    position: absolute;
    top:100%;
    /* height: 100%; */
    width: 146px;
    transition: ease-in-out 0.3s;
    overflow: hidden;
}
.hed_user_close{
    background-color: red;
    border-radius: 0 0 5px 5px;
    position: absolute;
    top:100%;
    width: 146px;
    height: 0%;
    transition: ease-in-out 0.3s;
    overflow: hidden;
}