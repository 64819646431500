.stats_modal_highblock{
    background-color: #2D3039;
    border-radius: 10px;
    margin:1%;
    flex:1;
    display:flex;
    flex-direction: column;
    font-size: 14px;
    padding: 0.5rem;
    color: #e8a736be;
}
.stats_main{
    padding: 3% 10% 15%;
    background-color: #1D2029;
    position:absolute;
    bottom: 0;
    left:0;
    width:100%;
    transition: ease-out 0.3s;
    z-index: 4;
    
    border-radius: 5% 5% 0 0 ;
}
.stats_main_close{
    
    padding: 3% 10%;
    position:absolute;
    bottom: -100%;
    left:0;
    width:100%;
    transition: ease-out 0.3s;
    z-index: 4;
    
    border-radius: 5% 5% 0 0 ;
    background-color: #1D2029;
}
.stats_dropmenu{
    background-color: #2D3039;
    border-radius: 10px;
    color:#9B9B9B;
    padding: 0.7rem;
    font-size: 14px;
    margin:3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.stats_item_upper_part{
    border-radius: 10px 10px 0 0;
    display:flex;
    flex-direction:'row';
    transition:0.3s;
}
.stats_item_lower_part{
    border-radius:  0 0 10px 10px;
    overflow: hidden;
    transition:0.3s;
}
.stats_item{
    transition: 0.3s;
    margin:0.5rem 0;
    border-radius:10px;
    padding:0.3rem 0.5rem 0 0.3rem;
}
.stats_button{
    background-color:rgba(0,0,0,0);
    border:none;
    color:#C9C9C9;
    padding:0;
}
.stats_button:focus-visible{
    outline: none;
}
.stats_button:focus{
    outline: none;
}