.oper_main{
    background-color: #1D2029;
    height: 100%;
    padding: 0 5%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    /* flex:1; */
    position: absolute;
    transition: ease-in 0.2s;
}


.oper_title{
    color: #E8A736;
    text-align: left;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    width: 100%;

}
.oper_icon_pos{
    position: absolute;
    top: 0.5rem;
    left:1rem;
    color: #585b667e;
    font-weight: 100;
}
.oper_form{
    background-color: #4e566e50;
    margin: 0;
    border-radius: 10px;
    border: 1px solid #e8a73600;
    padding: 0.3rem  2.5rem ;
    width: 100%;
    font-size:14px;
    z-index: 0;
    color:#ffffff8c;
}
.oper_form:focus-visible{
    outline:1px solid #ffffff8c;
}
.oper_form::placeholder{
    color:#585B66;
}

.oper_high_block{
    background-color: #2D3039;
    border-radius: 10px;
    margin:1%;
    flex:1;
    display:flex;
    flex-direction: column;
    font-size: 14px;
    padding: 0.5rem;
    color: #e8a736be;
}

.oper_dropmenu{
    background-color: #2D3039;
    border-radius: 10px;
    color:#9B9B9B;
    padding: 0.1rem 1rem;
    font-size: 14px;
    margin:3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.oper_dropmenu_activ_user{
    background-color: #2D3039;
    border-radius: 10px;
    color:#9B9B9B;
    padding: 0.1rem 1rem;
    font-size: 14px;
    margin-top:3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.oper_dropmenu_toggle{
    color:#9B9B9B;
    border-radius:10px;
    min-width:140px;
    background: #2d3039 !important;
    font-size:14px;
    
}
.oper_dropmenu_toggle_userSelect{
    color:#9B9B9B;
    border-radius:10px;
    min-width:140px;
    /* height: 0%;
    padding: 4%; */
    /* background: #2d3039 !important; */
    font-size:14px;
    
}
.oper_dropmenu_toggle>div{
    color:#9B9B9B;
    background: #2d3039 !important;
    font-size:14px;
    
}
.oper_dropmenu_toggle>button{
    color:#9B9B9B!important;
    background: #2d3039 !important;
    font-size:14px;
    border-radius: 10px;
    
}
.oper_dropmenu_toggle>button:focus{
    outline: none;
    box-shadow:none;
    
}
.oper_dropmenu_toggle:focus{
    box-shadow:none;
}
.oper_dropmenu_toggle:focus-visible{
    outline: none;
}

.oper_table{
    overflow: scroll;
     /* max-height: 55%; */
     padding: 0 10px 1rem 0;
}
.selectOper{
    width:10px; 
    background: color #2d3039 !important;
    font-size:15px;
}

.block_element{
    pointer-events: none
}

.oper_block_full{
    height: 32px;
    background-color: #2D3039;
    border-radius: 10px;
    margin: 1%;
    display: flex;
    flex-direction: row;
    padding: 0.6rem;
    flex: 0 1!important;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    margin-right: 1%;
    align-content: center;
    align-items: center;
}
.oper_block_full>img {
    margin-left: 5px;
    height: 18px;
}

.oper_title_and_block{

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}