.ext_modal{
    position: fixed;
    background-color: #1D2029;
    color:#E8A736;
    width:250px;
    left:50%;
    top:50%;
    padding:5% 0 ;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    transition: linear 0.2s;
    z-index: 5;
    transform:translate(-50%,-50%)
    
}
.ext_modal_p{
    position: absolute;
    color: #E8A736a9;
    font-size: 12px;
    transition:ease-out 0.3s;
    top:0;
    text-align: center;
    padding: 0 5%;
    /* width: 200px; */
}
.ext_modal_close{
    position: fixed;
    background-color: #1D2029;
    color:#E8A736;
    margin:10%;
    left:0;
    top:-100%;
    padding:5%;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    transition: linear 0.2s;
    z-index: 5;
}
.ext_button{
    border: 1px solid #e8a736;
    background-color: #1D2029;
    border-radius: 10px;
    color: #E8A736;
    padding: 0.5rem 0;
    margin:0 25%;
    width: 50%;
    font-weight: 500;
}
.ext_cancel_button{
    border:none;
    background-color: #1D2029;
    color: #B20000;
    margin:10% 25% 5%;
    width: 50%;
    font-weight: 700;
}
.ext_modal_blur{
    overflow: hidden;
    top:0;
    left:0;
    position:fixed;
    width: 100%;
    height: 100%;
    z-index: 5;
    backdrop-filter: blur(5px)  brightness(80%);
}
.ext_modal_blur_close{
    overflow: hidden;
    top:0;
    left:0;
    position:absolute;
    width: 0%;
    height: 0%;
    z-index: 5;
    backdrop-filter: blur(5px)  brightness(80%);
}
