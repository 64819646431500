.day_curs_main{
    position: absolute;
    top:-50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #1D2029;
    color:#E8A736;    
    padding:10% 5%;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    transition: ease-out 0.3s;
    z-index: 5;
}
.day_curs_blur{
    top:0;
    left:0;
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    backdrop-filter: blur(5px)  brightness(80%);
}

.day_curs_button{
    color:#E8A736;
    border: 1px solid #E8A736;
    background-color: #1D2029;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    /* width: 100%; */
    margin:1rem 3rem;
}
.day_curs_legend{
    display: block;
    width: auto;
    padding: 0 0.5rem;
    height: 21px;
    font-size: 0.75em;
    max-width: 100%;
    text-align: left;
    color:#E8A736;
    margin:0;
    margin-left:1rem;
    float:none;

    
}
.day_curs_form{
    background-color:#00000000;
    position: absolute;
    width: 100%;
    top:1rem;
    left:0;
    border: none;
    border-radius:10px;
    color:#E8A736;
    text-align: center;
    font-size: 18px;
}
.day_curs_form:focus-visible{
    border:none;
    outline: none;
}