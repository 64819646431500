.mess_main{
    background-color: #1D2029;
    height: 100%;
    padding: 0 5%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex:1;
    position: absolute;
    transition: ease-in 0.2s;
    overflow: hidden;
}

.mess_title{
    color: #E8A736;
    text-align: left;
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    width: 100%;

}
.mess_icon_pos{
    position: absolute;
    top: 0.5rem;
    left:1rem;
    color: #585b667e;
    font-weight: 100;

}
.mess_form{
    background-color: #4e566e50;
    margin: 0;
    border-radius: 10px;
    border: 1px solid #e8a73600;
    padding: 0.3rem  2.5rem ;
    width: 100%;
    font-size:14px;
    color:#ffffff8c;
}
.mess_form:focus-visible{
    outline:1px solid #ffffff8c;
}
.mess_form::placeholder{
    color: #585B66;
}
.mess_dropmenu{
    background-color: #2D3039;
    border-radius: 10px;
    color:#9B9B9B;
    padding: 0.1rem 1.5rem;
    font-size: 14px;
    margin:3%;
}
.mess_item{
    border-bottom: #C9C9C9 1px solid;
    text-align: start;
    font-size:14px;
}
.mess_item_span{
    height:3rem;
    overflow: hidden;
    transition: ease-in-out 0.3s;
    display: flex;
}
.mess_item_span_closed{
    display: flex;
    height:0px;
    overflow: hidden;
    transition: ease-in-out 0.3s;
}
.mess_item_button{
    color:#C9C9C9!important;
    border: #C9C9C9 1px solid;
    border-radius: 5px;
    font-size: 14px;
    background-color:  #e8a73600;
    padding: 0.3rem 1rem;
    margin:auto 0;
}
.mess_href{
    color:#C9C9C9!important;
    text-decoration: none;
}
.mess_href:hover{
    color:#C9C9C9!important;
    text-decoration: none;
}