.user_sel_main{
    background-color:#1D2029;
    padding: 5% 0 20%;
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    
    overflow: hidden;
    
    transition: ease-in-out 0.3s;
}
.user_sel_main_close{
    background-color:#1D2029;
    padding: 5% 0 10%;
    position: absolute;
    top:0;
    left:-100%;
    width: 100%;
    height:100%;
    z-index: 3;
    transition: ease-in-out 0.3s;
}
.user_sel_but{
    flex:1;   
    color:#fff;
    font-size: 10px;
    cursor:pointer;
}
.user_sel_but>img{
    height: 50px;
}
.user_sel_left{
    text-align: left;
    color:#c9c9c9;
    margin-bottom:0.5rem;
    font-size:12px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 16px;
  }
  
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:#1D2029;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    /* left: 1px; */
    bottom: 1px;
    background-color: #454b5c;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: rgb(232, 167, 54);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px rgb(232, 167, 54);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
    background-color: #cacdd3;
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }